import * as React from "react";

import { Layout } from "../components/layout";

const IndexPage = () => (
  <Layout>
    <p>C'était du bluff, elles ne sont pas encore là.</p>
  </Layout>
);

export default IndexPage;
